import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

// import { userService } from 'services';
import { userService } from "./../components/auth/userService";

export default Login;

function Login() {
  const [valid, setValid] = useState(true);
  const router = useRouter();

  useEffect(() => {
    // redirect to home if already logged in
    if (userService.userValue) {
      router.push("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, setError, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit({ username, password }) {
    return userService
      .login(username, password)
      .then(() => {
        let user = localStorage.getItem("user");
        const returnUrl = router.query.returnUrl || "/";
        if (user) {
          router.push(returnUrl);
        }
        // get return url from query parameters or default to '/'
      })
      .catch((error) => {
        window.alert("Please enter a valid username or password");
        // setError('apiError', { message: error });
      });
  }

  return (
    <div
      className="card w-[700px] p-[70px] mx-auto absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
      style={{
        backgroundColor: "#fff",
        boxShadow: "0 12px 15px 0 rgb(0 0 0 / 25%)",
      }}
    >
      {/* <h4 className="card-header">Next.js Basic Authentication Example</h4> */}
      <div className="card-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group mb-[30px]">
            <label className="w-[50%] inline-block">Username</label>
            <input
              name="username"
              type="text"
              {...register("username")}
              className={`border outline-none py-[5px] px-[10px] w-[50%] form-control ${
                errors.username ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">{errors.username?.message}</div>
          </div>
          <div className="form-group mb-[30px]">
            <label className="w-[50%] inline-block">Password</label>
            <input
              name="password"
              type="password"
              {...register("password")}
              className={`border outline-none py-[5px] px-[10px] w-[50%] form-control ${
                errors.password ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">{errors.password?.message}</div>
          </div>
          <div className="login-submit text-center mt-[40px]">
            <button
              disabled={formState.isSubmitting}
              className="btn btn-primary text-[#fff] border-[1px] border-[#001151] w-[171px] h-[40px] rounded-[90px] bg-[#001151] text-[13px];"
            >
              {formState.isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Login
            </button>
          </div>
          {errors.apiError && (
            <div className="alert alert-danger mt-3 mb-0">
              {errors.apiError?.message}
            </div>
          )}
        </form>
      </div>
    </div>
    // </div>
  );
}
